import {
  BackgroundImage,
  Box,
  SimpleGrid,
  Space,
  Stack,
  Text,
  rem,
  useMantineTheme,
} from "@mantine/core";
import React from "react";
import { Colors } from "../../lib/colors";

export default function CompletedProjects(props) {
  const theme = useMantineTheme();

  return (
    <section id="completed-projects">
      <Box p={rem(150)} visibleFrom="md">
        <Text fw={600} size="lg">
          We have completed many projects including the following:
        </Text>
        <Space h="xl" />
        <SimpleGrid cols={2}>
          <BackgroundImage
            src="/images/project-one.svg"
            mih={rem(270)}
            radius={16}
            p={"xl"}
          >
            <Stack>
              <img
                src={"/images/oldinyo-water-project.png"}
                style={{
                  width: "100%",
                  height: rem(300),
                  objectFit: "cover",
                  borderRadius: 16,
                }}
                alt="oldinyo"
              />
              <Text fw={600} c={Colors.brandTwo} size="xl">
                Oldinyo
              </Text>
              <Text size="sm" c={theme.colors.gray[7]}>
                This is a Community Based Organization situated in Narok County.
                The project was kick started by John Nkaate for his community
                who spoke to a donor and involved Untapped Water and other
                stakeholders to provide water for his community. The project
                continues to serve many people and their livestock which are
                their source of livelihood.  The community now fully own and
                enjoy the project after successfully repaying the leased amount
                from the water sales.
              </Text>
            </Stack>
          </BackgroundImage>
          <BackgroundImage
            src="/images/project-two.svg"
            mih={rem(270)}
            radius={16}
            p={"xl"}
          >
            <Stack>
              <img
                src={"/images/laviso.png"}
                style={{
                  width: "100%",
                  height: rem(300),
                  objectFit: "cover",
                  borderRadius: 16,
                }}
                alt="laviso"
              />
              <Text fw={600} c={Colors.brandTwo} size="xl">
                Laviso
              </Text>
              <Text size="sm" c={theme.colors.gray[7]}>
                Situated in Migori County, LAVISO is a CBO that supports
                families and invdividuals who have been affected by HIV/AIDS.
                Being a very organized group, they manage the water Kiosk and
                use the revenues collected from the water sales to conduct the
                other health projects they undertake in their community.
              </Text>
            </Stack>
          </BackgroundImage>
          <BackgroundImage
            src="/images/project-three.svg"
            mih={rem(270)}
            radius={16}
            p={"xl"}
          >
            <Stack>
              <img
                src={"/images/mfutuline.png"}
                style={{
                  width: "100%",
                  height: rem(300),
                  objectFit: "fill",
                  borderRadius: 16,
                }}
                alt="mfutuline"
              />
              <Text fw={600} c={Colors.brandTwo} size="xl">
                Mfutuline
              </Text>
              <Text size="sm" c={theme.colors.gray[7]}>
                This ia a family owned water project in Bungoma County. The
                project is centrally located at the heart of the town and it
                continues to serve the people in this community who largely
                needed a water source.
              </Text>
            </Stack>
          </BackgroundImage>
          <BackgroundImage
            src="/images/project-four.svg"
            mih={rem(270)}
            radius={16}
            p={"xl"}
          >
            <Stack>
              <img
                src={"/images/esitao.png"}
                style={{
                  width: "100%",
                  height: rem(300),
                  objectFit: "fill",
                  borderRadius: 16,
                }}
                alt="esitao"
              />
              <Text fw={600} c={Colors.brandTwo} size="xl">
                Esitao
              </Text>
              <Text size="sm" c={theme.colors.gray[7]}>
                Mr Mbalanya saw the water need in his community in Vihiga County
                and decided to do something about it. He set up this water
                project which provides water for his community at very
                subsidized rates. He provides water for free in case of communal
                emergencies such as fire outbreaks. His community continues to
                enjoy his efforts of sourcing the funds to put up this great
                project.
              </Text>
            </Stack>
          </BackgroundImage>
        </SimpleGrid>
      </Box>

      <Box px={"md"} py={"50px"} hiddenFrom="md">
        <Text fw={600} size="lg">
          We have completed many projects including the following:
        </Text>
        <Space h="xl" />
        <SimpleGrid cols={1}>
          <BackgroundImage
            src="/images/project-one.svg"
            mih={rem(350)}
            radius={16}
            p={"xl"}
          >
            <Stack>
              <img
                src={"/images/oldinyo-water-project.png"}
                style={{
                  width: "100%",
                  height: rem(250),
                  objectFit: "cover",
                  borderRadius: 16,
                }}
                alt="oldinyo"
              />
              <Text fw={600} c={Colors.brandTwo} size="xl">
                Oldinyo
              </Text>
              <Text size="sm" c={theme.colors.gray[7]}>
                This is a Community Based Organization situated in Narok County.
                The project was kick started by John Nkaate for his community
                who spoke to a donor and involved Untapped Water and other
                stakeholders to provide water for his community. The project
                continues to serve many people and their livestock which are
                their source of livelihood.  The community now fully own and
                enjoy the project after successfully repaying the leased amount
                from the water sales.
              </Text>
            </Stack>
          </BackgroundImage>
          <BackgroundImage
            src="/images/project-two.svg"
            mih={rem(350)}
            radius={16}
            p={"xl"}
          >
            <Stack>
              <img
                src={"/images/laviso.png"}
                style={{
                  width: "100%",
                  height: rem(250),
                  objectFit: "cover",
                  borderRadius: 16,
                }}
                alt="laviso"
              />
              <Text fw={600} c={Colors.brandTwo} size="xl">
                Laviso
              </Text>
              <Text size="sm" c={theme.colors.gray[7]}>
                Situated in Migori County, LAVISO is a CBO that supports
                families and invdividuals who have been affected by HIV/AIDS.
                Being a very organized group, they manage the water Kiosk and
                use the revenues collected from the water sales to conduct the
                other health projects they undertake in their community.
              </Text>
            </Stack>
          </BackgroundImage>
          <BackgroundImage
            src="/images/project-three.svg"
            mih={rem(350)}
            radius={16}
            p={"xl"}
          >
            <Stack>
              <img
                src={"/images/mfutuline.png"}
                style={{
                  width: "100%",
                  height: rem(250),
                  objectFit: "fill",
                  borderRadius: 16,
                }}
                alt="mfutuline"
              />
              <Text fw={600} c={Colors.brandTwo} size="xl">
                Mfutuline
              </Text>
              <Text size="sm" c={theme.colors.gray[7]}>
                This ia a family owned water project in Bungoma County. The
                project is centrally located at the heart of the town and it
                continues to serve the people in this community who largely
                needed a water source.
              </Text>
            </Stack>
          </BackgroundImage>
          <BackgroundImage
            src="/images/project-four.svg"
            mih={rem(350)}
            radius={16}
            p={"xl"}
          >
            <Stack>
              <img
                src={"/images/esitao.png"}
                style={{
                  width: "100%",
                  height: rem(250),
                  objectFit: "fill",
                  borderRadius: 16,
                }}
                alt="esitao"
              />
              <Text fw={600} c={Colors.brandTwo} size="xl">
                Esitao
              </Text>
              <Text size="sm" c={theme.colors.gray[7]}>
                Mr Mbalanya saw the water need in his community in Vihiga County
                and decided to do something about it. He set up this water
                project which provides water for his community at very
                subsidized rates. He provides water for free in case of communal
                emergencies such as fire outbreaks. His community continues to
                enjoy his efforts of sourcing the funds to put up this great
                project.
              </Text>
            </Stack>
          </BackgroundImage>
        </SimpleGrid>
      </Box>
    </section>
  );
}

import MainLayout from "../../components/MainLayout";
import AboutUs from "./AboutUs";
import CompletedProjects from "./CompletedProjects";
import ContactUs from "./ContactUs";
import CostBreakdown from "./CostBreakdown";
import Empowerment from "./Empowerment";
import Equity from "./Equity";
import GetInvovled from "./GetInvovled";
import Home from "./Home";
import Solution from "./Solution";
import Testimonials from "./Testimonials";
import WhoWeAre from "./WhoWeAre";
import WhyThisIsImportant from "./WhyThisIsImportant";

export default function HomePage() {
  return (
    <MainLayout>
      <Home />
      <WhoWeAre />
      <WhyThisIsImportant />
      <Solution />
      <Equity />
      <CostBreakdown />
      <Empowerment />
      <CompletedProjects />
      <Testimonials />
      <AboutUs />
      <GetInvovled />
      <ContactUs />
    </MainLayout>
  );
}

import {
  BackgroundImage,
  Box,
  Flex,
  Group,
  Space,
  Text,
  rem,
} from "@mantine/core";
import React from "react";
import { Colors } from "../../lib/colors";

export default function WhyThisIsImportant(props) {
  return (
    <section id="why-this-is-important">
      <Box
        bg={Colors.brandOne}
        fw={600}
        size={"md"}
        p={rem(150)}
        visibleFrom="md"
      >
        <Text tt={"uppercase"} fw={600} size="lg">
          Why this is important
        </Text>
        <Space h="xl" />
        <Group justify="space-between" grow>
          <BackgroundImage src="/images/wtii-one.svg" h={"50dvh"}>
            <Flex direction={"column"} h={"50dvh"} p={"md"}>
              <Text size="45px" fw={700} c={"#fff"}>
                41%
              </Text>

              <Text c={"#fff"} mt={"auto"} size="lg">
                Kenya’s population lacking access to clean, affordable and
                reliable water sources.
              </Text>
            </Flex>
          </BackgroundImage>

          <BackgroundImage src="/images/wtii-two.svg" h={"50dvh"}>
            <Flex direction={"column"} h={"50dvh"} p={"md"}>
              <Text size="45px" fw={700} c={Colors.brandTwo}>
                65%
              </Text>

              <Text c={Colors.brandTwo} mt="auto" size="lg">
                Women and girls in Kenya’s rural areas with limited access to
                affordable and sustainable menstrual hygiene products.
                <span style={{ color: "red" }}>*</span>
              </Text>
            </Flex>
          </BackgroundImage>

          <BackgroundImage src="/images/wtii-three.svg" h={"50dvh"}>
            <Flex direction={"column"} h={"50dvh"} p={"md"}>
              <Text size="45px" fw={700} c={"#fff"}>
                5,000
              </Text>

              <Text c={"#fff"} mt="auto" size="lg">
                Kenya’s population lacking access to clean, affordable and
                reliable water sources.
              </Text>
            </Flex>
          </BackgroundImage>
        </Group>
        <Space h="xl" />
        <Group wrap="nowrap" align="flex-start">
          <span style={{ color: "red" }}>*</span>

          <Box>
            <Text fw={600} size="sm">
              A socio-cultural problem
            </Text>
            <Text size="sm">
              Persistent taboos prevent open discussions about menstrual health,
              affecting women’s confidence and participation in community life.
            </Text>
          </Box>
        </Group>
      </Box>

      <Box
        bg={Colors.brandOne}
        fw={600}
        size={"md"}
        px={"md"}
        py={"50px"}
        hiddenFrom="md"
      >
        <Text tt={"uppercase"} fw={600} size="lg">
          Why this is important
        </Text>
        <Space h="xl" />
        <Group justify="space-between">
          <BackgroundImage src="/images/wtii-one.svg" h={"50dvh"}>
            <Flex direction={"column"} h={"50dvh"} p={"md"}>
              <Text size="45px" fw={700} c={"#fff"}>
                41%
              </Text>

              <Text c={"#fff"} mt={"auto"}>
                Kenya’s population lacking access to clean, affordable and
                reliable water sources.
              </Text>
            </Flex>
          </BackgroundImage>

          <BackgroundImage src="/images/wtii-two.svg" h={"50dvh"}>
            <Flex direction={"column"} h={"50dvh"} p={"md"}>
              <Text size="45px" fw={700} c={Colors.brandTwo}>
                65%
              </Text>

              <Text c={Colors.brandTwo} mt="auto">
                Women and girls in Kenya’s rural areas with limited access to
                affordable and sustainable menstrual hygiene products.
                <span style={{ color: "red" }}>*</span>
              </Text>
            </Flex>
          </BackgroundImage>

          <BackgroundImage src="/images/wtii-three.svg" h={"50dvh"}>
            <Flex direction={"column"} h={"50dvh"} p={"md"}>
              <Text size="45px" fw={700} c={"#fff"}>
                5,000
              </Text>

              <Text c={"#fff"} mt="auto">
                Kenya’s population lacking access to clean, affordable and
                reliable water sources.
              </Text>
            </Flex>
          </BackgroundImage>
        </Group>
        <Space h="xl" />
        <Group wrap="nowrap" align="flex-start">
          <span style={{ color: "red", marginTop: "2px" }}>*</span>

          <Box>
            <Text fw={600} size="sm">
              A socio-cultural problem
            </Text>
            <Text size="sm">
              Persistent taboos prevent open discussions about menstrual health,
              affecting women’s confidence and participation in community life.
            </Text>
          </Box>
        </Group>
      </Box>
    </section>
  );
}

import axios from "axios";
import { airtable } from "../lib/api";

export const fetchSites = async (props) => {
  const { onSuccess, onFailure, startLoader, stopLoader } = props;

  const sitesUrl = `${airtable.baseUrl}${airtable.baseId}/sites`;

  try {
    if (startLoader) {
      startLoader();
    }

    const data = await axios.get(sitesUrl, {
      headers: {
        Authorization: `Bearer ${airtable.apiKey}`,
      },
    });

    if (onSuccess) {
      onSuccess(data);
    }

    if (stopLoader) {
      stopLoader();
    }
  } catch (error) {
    if (onFailure) {
      onFailure();
    }
    if (stopLoader) {
      stopLoader();
    }
  }
};

import React from "react";
import {
  Box,
  Group,
  Paper,
  SimpleGrid,
  Space,
  Stack,
  Text,
  rem,
  useMantineTheme,
} from "@mantine/core";
import { Colors } from "../../lib/colors";
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandYoutube,
  IconCopyright,
  IconMailOpened,
  IconPhone,
} from "@tabler/icons-react";

export default function ContactUs(props) {
  const theme = useMantineTheme();

  return (
    <section id="contact-us">
      <Box
        px={rem(150)}
        pt={rem(50)}
        pb={rem(25)}
        visibleFrom="md"
        bg={Colors.brandTwo}
      >
        <Text ta={"center"} tt="uppercase" size="xl" fw={600} c={"#fff"}>
          Contact Us
        </Text>
        <Space h="sm" />
        <Text ta={"center"} c={"#fff"}>
          Do you want to visit the sites and see the impact your help is making?
          Welcome, Anne <br /> will show you around 😃
        </Text>
        <Space h={"50px"} />
        <SimpleGrid cols={3}>
          <Paper radius={16} p={"xl"}>
            <Stack gap={5}>
              <IconMailOpened size={32} color={Colors.brandTwo} stroke={1.5} />
              <Text fw={600}>Email</Text>
              <Text
                c={theme.colors.gray[7]}
                component="a"
                href="mailto:info@untapped-water.com"
                className="media-link"
              >
                info@untapped-water.com
              </Text>
            </Stack>
          </Paper>
          <Paper radius={16} p={"xl"}>
            <Stack gap={5}>
              <IconPhone size={32} color={Colors.brandTwo} stroke={1.5} />
              <Text fw={600}>Phone</Text>
              <Text c={theme.colors.gray[7]}>+254 707 864 222</Text>
            </Stack>
          </Paper>
          <Paper radius={16} p={"xl"}>
            <Stack gap={5}>
              <IconBrandInstagram
                size={32}
                color={Colors.brandTwo}
                stroke={1.5}
              />
              <Text fw={600}>Instagram</Text>
              <Text
                c={theme.colors.gray[7]}
                component="a"
                target="_blank"
                className="media-link"
                href="https://www.instagram.com/untapped_water_kenya?igsh=NDh4bTBtYnJ0aGE1"
              >
                Untapped_Water_Kenya
              </Text>
            </Stack>
          </Paper>
          <Paper radius={16} p={"xl"}>
            <Stack gap={5}>
              <IconBrandFacebook
                size={32}
                color={Colors.brandTwo}
                stroke={1.5}
              />
              <Text fw={600}>Facebook</Text>
              <Text
                c={theme.colors.gray[7]}
                component="a"
                target="_blank"
                className="media-link"
                href="https://www.facebook.com/people/Untapped-Water-Kenya/61566547436560/?mibextid=LQQJ4d&rdid=ElniMOMJiFiSQm9W&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FgRX31oZsjMRT58jp%2F%3Fmibextid%3DLQQJ4d"
              >
                Untapped_Water_Kenya
              </Text>
            </Stack>
          </Paper>
          <Paper radius={16} p={"xl"}>
            <Stack gap={5}>
              <IconBrandYoutube
                size={32}
                color={Colors.brandTwo}
                stroke={1.5}
              />
              <Text fw={600}>Youtube</Text>
              <Text
                c={theme.colors.gray[7]}
                component={"a"}
                href="https://www.youtube.com/@untappedwater_ke-l6k"
                target="_blank"
                className="media-link"
              >
                Untapped_Water_Kenya
              </Text>
            </Stack>
          </Paper>
        </SimpleGrid>
        <Space h={"50px"} />
        <Box w={"100%"} h={rem(600)}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3988.8444829716564!2d36.769258!3d-1.2659399999999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMcKwMTUnNTcuNCJTIDM2wrA0NicwOS4zIkU!5e0!3m2!1sen!2ske!4v1732621895810!5m2!1sen!2ske"
            width="100%"
            height="100%"
            style={{ border: 0, borderRadius: 24 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="maps"
          ></iframe>
        </Box>
        <Space h="25px" />
        <Group justify="center">
          <Group gap={5} c="white">
            <IconCopyright stroke={1.5} size={20} />
            <Text>Untapped Water. All rights reserved</Text>
          </Group>
        </Group>
      </Box>

      <Box
        px={"md"}
        pt={"50px"}
        pb={"25px"}
        hiddenFrom="md"
        bg={Colors.brandTwo}
      >
        <Text ta={"center"} tt="uppercase" size="xl" fw={600} c={"#fff"}>
          Contact Us
        </Text>
        <Space h="sm" />
        <Text ta={"center"} c={"#fff"}>
          Do you want to visit the sites and see the impact your help is making?
          Welcome, Anne <br /> will show you around 😃
        </Text>
        <Space h={"50px"} />
        <SimpleGrid cols={1}>
          <Paper radius={16} p={"xl"}>
            <Stack gap={5}>
              <IconMailOpened size={32} color={Colors.brandTwo} stroke={1.5} />
              <Text fw={600}>Email</Text>
              <Text
                c={theme.colors.gray[7]}
                component="a"
                href="mailto:info@untapped-water.com"
                className="media-link"
              >
                info@untapped-water.com
              </Text>
            </Stack>
          </Paper>
          <Paper radius={16} p={"xl"}>
            <Stack gap={5}>
              <IconPhone size={32} color={Colors.brandTwo} stroke={1.5} />
              <Text fw={600}>Phone</Text>
              <Text c={theme.colors.gray[7]}>+254 707 864 222</Text>
            </Stack>
          </Paper>
          <Paper radius={16} p={"xl"}>
            <Stack gap={5}>
              <IconBrandInstagram
                size={32}
                color={Colors.brandTwo}
                stroke={1.5}
              />
              <Text fw={600}>Instagram</Text>
              <Text
                c={theme.colors.gray[7]}
                component="a"
                target="_blank"
                className="media-link"
                href="https://www.instagram.com/untapped_water_kenya?igsh=NDh4bTBtYnJ0aGE1"
              >
                Untapped_Water_Kenya
              </Text>
            </Stack>
          </Paper>
          <Paper radius={16} p={"xl"}>
            <Stack gap={5}>
              <IconBrandFacebook
                size={32}
                color={Colors.brandTwo}
                stroke={1.5}
              />
              <Text fw={600}>Facebook</Text>
              <Text
                c={theme.colors.gray[7]}
                component="a"
                target="_blank"
                className="media-link"
                href="https://www.facebook.com/people/Untapped-Water-Kenya/61566547436560/?mibextid=LQQJ4d&rdid=ElniMOMJiFiSQm9W&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FgRX31oZsjMRT58jp%2F%3Fmibextid%3DLQQJ4d"
              >
                Untapped_Water_Kenya
              </Text>
            </Stack>
          </Paper>
          <Paper radius={16} p={"xl"}>
            <Stack gap={5}>
              <IconBrandYoutube
                size={32}
                color={Colors.brandTwo}
                stroke={1.5}
              />
              <Text fw={600}>Youtube</Text>
              <Text
                c={theme.colors.gray[7]}
                component={"a"}
                href="https://www.youtube.com/@untappedwater_ke-l6k"
                target="_blank"
                className="media-link"
              >
                Untapped_Water_Kenya
              </Text>
            </Stack>
          </Paper>
        </SimpleGrid>
        <Space h={"50px"} />
        <Box w={"100%"} h={rem(400)}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3988.8444829716564!2d36.769258!3d-1.2659399999999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMcKwMTUnNTcuNCJTIDM2wrA0NicwOS4zIkU!5e0!3m2!1sen!2ske!4v1732621895810!5m2!1sen!2ske"
            width="100%"
            height="100%"
            style={{ border: 0, borderRadius: 24 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="maps"
          ></iframe>
        </Box>
        <Space h="25px" />
        <Group justify="center">
          <Group gap={5} c="white">
            <IconCopyright stroke={1.5} size={20} />
            <Text>Untapped Water. All rights reserved</Text>
          </Group>
        </Group>
      </Box>
    </section>
  );
}

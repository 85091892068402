import { Box, Group, Image, Stack, Text, rem } from "@mantine/core";
import { Colors } from "../../lib/colors";

export default function WhoWeAre() {
  return (
    <section id="who-we-are">
      <Box p={rem(150)} visibleFrom="md">
        <Group align="flex-start" gap={"xl"}>
          <Box flex={1}>
            <Stack>
              <Text
                c={"dimmed"}
                style={{
                  fontSize: "24px",
                  lineHeight: 1.2,
                }}
                fw={600}
              >
                Untapped was established in 2019 with a mission to provide
                <Text
                  component="span"
                  c={Colors.brandTwo}
                  style={{
                    fontSize: "24px",
                    lineHeight: 1.2,
                  }}
                  fw={600}
                >
                  {" "}
                  innovative water solutions
                </Text>
                . In 2024, we expanded our focus to include{" "}
                <Text
                  component="span"
                  c={Colors.brandTwo}
                  style={{
                    fontSize: "24px",
                    lineHeight: 1.2,
                  }}
                  fw={600}
                >
                  {" "}
                  female hygiene initiatives
                </Text>
                .
              </Text>
              <Text
                c={"dimmed"}
                style={{
                  fontSize: "24px",
                  lineHeight: 1.2,
                }}
                fw={600}
              >
                To date, we have successfully installed{" "}
                <Text
                  component="span"
                  c={Colors.brandTwo}
                  style={{
                    fontSize: "24px",
                    lineHeight: 1.2,
                  }}
                  fw={600}
                >
                  {" "}
                  63 sites
                </Text>
                , serving approximately{" "}
                <Text
                  component="span"
                  c={Colors.brandTwo}
                  style={{
                    fontSize: "24px",
                    lineHeight: 1.2,
                  }}
                  fw={600}
                >
                  {" "}
                  400 customers
                </Text>{" "}
                at each location, which benefits around{" "}
                <Text
                  component="span"
                  c={Colors.brandTwo}
                  style={{
                    fontSize: "24px",
                    lineHeight: 1.2,
                  }}
                  fw={600}
                >
                  {" "}
                  25,000 individuals
                </Text>{" "}
                in total.
              </Text>
              <Text
                c={"dimmed"}
                size="xl"
                style={{
                  fontSize: "24px",
                  lineHeight: 1.2,
                }}
                fw={600}
              >
                Our goal is to set up three new sites each month, aiming for a
                total of{" "}
                <Text
                  component="span"
                  c={Colors.brandTwo}
                  style={{
                    fontSize: "24px",
                    lineHeight: 1.2,
                  }}
                  fw={600}
                >
                  {" "}
                  36 sites by the end of the year
                </Text>
                .
              </Text>
            </Stack>
          </Box>
          <Box flex={1}>
            <Image
              src={"/images/oldinyo-water-project.png"}
              width={"100%"}
              radius={24}
            />
          </Box>
        </Group>
      </Box>

      <Box hiddenFrom="md" mx={"md"} my={"50px"}>
        <Group align="flex-start" gap={"xl"}>
          <Box>
            <Stack>
              <Text c={"dimmed"} size="xl" fw={500}>
                Untapped was established in 2019 with a mission to provide
                <Text component="span" c={Colors.brandTwo} fw={500}>
                  {" "}
                  innovative water solutions
                </Text>
                . In 2024, we expanded our focus to include{" "}
                <Text component="span" c={Colors.brandTwo} fw={500}>
                  {" "}
                  female hygiene initiatives
                </Text>
                .
              </Text>
              <Text c={"dimmed"} size="xl" fw={500}>
                To date, we have successfully installed{" "}
                <Text component="span" c={Colors.brandTwo} fw={500}>
                  {" "}
                  63 sites
                </Text>
                , serving approximately{" "}
                <Text component="span" c={Colors.brandTwo} fw={500}>
                  {" "}
                  400 customers
                </Text>{" "}
                at each location, which benefits around{" "}
                <Text component="span" c={Colors.brandTwo} fw={500}>
                  {" "}
                  25,000 individuals
                </Text>{" "}
                in total.
              </Text>
              <Text c={"dimmed"} size="xl" fw={500}>
                Our goal is to set up three new sites each month, aiming for a
                total of{" "}
                <Text component="span" c={Colors.brandTwo} fw={500}>
                  {" "}
                  36 sites by the end of the year
                </Text>
                .
              </Text>
            </Stack>
          </Box>
          <Box>
            <Image src={"/images/oldinyo-water-project.png"} radius={24} />
          </Box>
        </Group>
      </Box>
    </section>
  );
}

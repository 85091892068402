import {
  Avatar,
  Box,
  Group,
  Image,
  Paper,
  Space,
  Stack,
  Text,
  rem,
  useMantineTheme,
} from "@mantine/core";
import React from "react";
import { Colors } from "../../lib/colors";
import { IconPennant } from "@tabler/icons-react";

export default function AboutUs(props) {
  const theme = useMantineTheme();

  return (
    <section id="about-us">
      <Box p={rem(150)} visibleFrom="md">
        <Text ta={"center"} mb={"xs"} size="xl" tt="uppercase" fw={600}>
          About Us
        </Text>
        <Text ta={"center"} c={theme.colors.gray[7]}>
          Learn more about our founders, partners and journey.
        </Text>
        <Space h={"50px"} />
        <Group wrap="nowrap" align="flex-start" gap={"50px"}>
          <Paper radius={24} bg={Colors.brandOne} p={32} w={rem(414)} flex={1}>
            <Image
              src="/images/about-us.svg"
              h={rem(350)}
              radius={24}
              w={rem(350)}
            />
            <Space h="sm" />
            <Stack gap={"xs"}>
              <Text c={Colors.brandTwo} fw={600}>
                Anne Kimemia
              </Text>
              <Text size="sm">
                Born and raised in Kenya, Anne is a wife and a mother of one. She
                is passionate about making a difference in her community by
                empowering them to lead better lives, helping them to set up and
                run sites that are their source of livelihood.
              </Text>

              <Text size="sm">
                She owns and runs the Untapped Water business in Kenya, which
                she has coupled with female hygiene products after realizing how
                prevalent period poverty is.
              </Text>
            </Stack>
          </Paper>

          <Stack gap={"sm"}>
            <Text size="md" style={{ fontWeight: "bold" }}>
              How We Got Started
            </Text>
            <Avatar bg={Colors.brandOne} radius={"xl"} size={40}>
              <IconPennant color={Colors.brandTwo} size={20} />
            </Avatar>

            <Text size={"15px"} style={{ lineHeight: 1.5 }}>
              Untapped Water was started in 2019 by an American impact investor,
              Jim Chu, who funded the first sites based on his experience with
              clean water distribution in Haiti.  As one of Untapped Water's
              first employees, Anne took the management of Untapped Water in
              2024
            </Text>
          </Stack>
        </Group>
      </Box>

      <Box px={"md"} py={"50px"} hiddenFrom="md">
        <Text ta={"center"} mb={"xs"} size="xl" tt="uppercase" fw={600}>
          About Us
        </Text>
        <Text ta={"center"} c={theme.colors.gray[7]}>
          Learn more about our founders, partners and journey.
        </Text>
        <Space h={"50px"} />

        <Stack>
          <Paper radius={24} bg={Colors.brandOne} p={32}>
            <Image src="/images/about-us.svg" radius={24} />
            <Space h="sm" />
            <Stack gap={"xs"}>
              <Text c={Colors.brandTwo} fw={600}>
                Anne Kimemia
              </Text>
              <Text size="sm">
                Born and raised in Kenya, Anne is a wife and a mother of one. She
                is passionate about making a difference in her community by
                empowering them to lead better lives, helping them to set up and
                run sites that are their source of livelihood.
              </Text>

              <Text size="sm">
                She owns and runs the Untapped Water business in Kenya, which
                she has coupled with female hygiene products after realizing how
                prevalent period poverty is.
              </Text>
            </Stack>
          </Paper>
          <Text size="md" style={{ fontWeight: "bold" }}>
            How We Got Started
          </Text>

          <Avatar bg={Colors.brandOne} radius={"xl"} size={40}>
            <IconPennant color={Colors.brandTwo} size={20} />
          </Avatar>

          <Text size="sm">
            Untapped Water was started in 2019 by an American impact investor,
            Jim Chu, who funded the first sites based on his experience with
            clean water distribution in Haiti.  As one of Untapped Water's first
            employees, Anne took the management of Untapped Water in 2024
          </Text>
        </Stack>
      </Box>
    </section>
  );
}

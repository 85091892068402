import React from "react";
import {
  Box,
  Card,
  Space,
  Text,
  rem,
  Group,
  useMantineTheme,
  Stack,
  Image,
} from "@mantine/core";

export default function Solution(props) {
  const theme = useMantineTheme();

  return (
    <section id="our-solution">
      <Box p={rem(150)} visibleFrom="md">
        <Text tt="uppercase" ta={"center"} fw={600} size="xl">
          Our Solution
        </Text>
        <Space h={"xs"} />
        <Text c={theme.colors.gray[7]} ta={"center"} size="lg">
          What we do best
        </Text>
        <Space h={"50px"} />
        <Group align="flex-start" grow gap={"xs"}>
          <Box>
            <Stack gap={"xs"}>
              <Card radius={24} bg={theme.colors.gray[1]} h={rem(260)} p={"xl"}>
                <Box
                  style={{
                    width: rem(80),
                    height: rem(80),
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  bg={"#fff"}
                >
                  <img
                    src="/images/coins.svg"
                    style={{ width: rem(50), height: rem(50) }}
                    alt="icon"
                  />
                </Box>
                <Space h="sm" />
                <Stack gap={"5px"}>
                  <Text fw={600}>
                    We provide financing and machine installation support to set
                    up water sites.
                  </Text>
                  <Text>
                    These sites provide accessible clean water at a low cost (10
                    cents for a 20-liter jerrican).
                  </Text>
                </Stack>
              </Card>
              <Card radius={24} bg={theme.colors.gray[1]} h={rem(260)} p={"xl"}>
                <Box
                  style={{
                    width: rem(80),
                    height: rem(80),
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  bg={"#fff"}
                >
                  <img
                    src="/images/users-three.svg"
                    style={{ width: rem(50), height: rem(50) }}
                    alt="icon"
                  />
                </Box>
                <Space h="sm" />
                <Stack gap={"5px"}>
                  <Text fw={600}>
                    Local entrepreneurs and community Based Organisations (CBOs)
                    run the sites to ensure the water equipment works and the
                    pad dispenser is stocked.
                  </Text>
                  <Text>
                    This leads to improved livelihood of the people in the
                    community.
                  </Text>
                </Stack>
              </Card>
            </Stack>
          </Box>
          <Box mt={rem(150)}>
            <Stack gap={"xs"}>
              <Card radius={24} bg={theme.colors.gray[1]} h={rem(260)} p={"xl"}>
                <Box
                  style={{
                    width: rem(80),
                    height: rem(80),
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  bg={"#fff"}
                >
                  <img
                    src="/images/tray-arrow-down.svg"
                    style={{ width: rem(50), height: rem(50) }}
                    alt="icon"
                  />
                </Box>
                <Space h="sm" />
                <Stack gap={"5px"}>
                  <Text fw={600}>
                    We set up feminine hygiene dispensers in each site.
                  </Text>
                  <Text>
                    The dispensers provide sanitary towels that women and girls
                    can afford (10 cents per pad instead of $1 per pack).
                  </Text>
                </Stack>
              </Card>
              <Card radius={24} bg={theme.colors.gray[1]} h={rem(260)} p={"xl"}>
                <Box
                  style={{
                    width: rem(80),
                    height: rem(80),
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  bg={"#fff"}
                >
                  <img
                    src="/images/wrench.svg"
                    style={{ width: rem(50), height: rem(50) }}
                    alt="icon"
                  />
                </Box>
                <Space h="sm" />
                <Stack gap={"5px"}>
                  <Text fw={600}>Support and maintenance.</Text>
                  <Text>
                    Untapped Water provides after support & maintenance.
                  </Text>
                </Stack>
              </Card>
            </Stack>
          </Box>
        </Group>

        <Space h={"50px"} />
        <Card
          radius={32}
          style={{
            background: "linear-gradient(94.81deg, #0E7CAF 0%, #156082 100%)",
          }}
          p="xl"
        >
          <Group wrap="nowrap" gap={80}>
            <img
              src="/images/zipporah.svg"
              style={{
                height: rem(300),
                width: rem(300),
                borderRadius: "20px",
              }}
              alt="zipporah"
            />

            <Stack gap={"sm"}>
              <Text size={"30px"} c={"#fff"} fw={600}>
                Meet Zipporah
              </Text>
              <Text c={"#fff"}>
                Zipporah is married with three children and lives in the Eastern
                part of Kenya. She runs a water and sanitary dispenser site. She
                ensures the machines works efficiently and the pad dispenser is
                always stocked.
              </Text>
            </Stack>
          </Group>
        </Card>
      </Box>

      <Box px="md" py="50px" hiddenFrom="md">
        <Text tt="uppercase" ta={"center"} fw={600} size="xl">
          Our Solution
        </Text>
        <Space h={"xs"} />
        <Text c={theme.colors.gray[7]} ta={"center"} size="lg">
          What we do best
        </Text>
        <Space h={"50px"} />
        <Stack>
          <Card radius={24} bg={theme.colors.gray[1]} h={rem(310)} p={"xl"}>
            <Box
              style={{
                width: rem(80),
                height: rem(80),
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              bg={"#fff"}
            >
              <img
                src="/images/coins.svg"
                style={{ width: rem(50), height: rem(50) }}
                alt="icon"
              />
            </Box>
            <Space h="sm" />
            <Stack gap={"5px"}>
              <Text fw={600}>
                We provide financing and machine installation support to set up
                water sites.
              </Text>
              <Text>
                These sites provide accessible clean water at a low cost (10
                cents for a 20-liter jerrican).
              </Text>
            </Stack>
          </Card>
          <Card radius={24} bg={theme.colors.gray[1]} h={rem(310)} p={"xl"}>
            <Box
              style={{
                width: rem(80),
                height: rem(80),
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              bg={"#fff"}
            >
              <img
                src="/images/users-three.svg"
                style={{ width: rem(50), height: rem(50) }}
                alt="icon"
              />
            </Box>
            <Space h="sm" />
            <Stack gap={"5px"}>
              <Text fw={600}>
                Local entrepreneurs and community Based Organisations (CBOs) run
                the sites to ensure the water equipment works and the pad
                dispenser is stocked.
              </Text>
              <Text>
                This leads to improved livelihood of the people in the
                community.
              </Text>
            </Stack>
          </Card>
          <Card radius={24} bg={theme.colors.gray[1]} h={rem(310)} p={"xl"}>
            <Box
              style={{
                width: rem(80),
                height: rem(80),
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              bg={"#fff"}
            >
              <img
                src="/images/tray-arrow-down.svg"
                style={{ width: rem(50), height: rem(50) }}
                alt="icon"
              />
            </Box>
            <Space h="sm" />
            <Stack gap={"5px"}>
              <Text fw={600}>
                We set up feminine hygiene dispensers in each site.
              </Text>
              <Text>
                The dispensers provide sanitary towels that women and girls can
                afford (10 cents per pad instead of $1 per pack).
              </Text>
            </Stack>
          </Card>
          <Card radius={24} bg={theme.colors.gray[1]} h={rem(310)} p={"xl"}>
            <Box
              style={{
                width: rem(80),
                height: rem(80),
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              bg={"#fff"}
            >
              <img
                src="/images/wrench.svg"
                style={{ width: rem(50), height: rem(50) }}
                alt="icon"
              />
            </Box>
            <Space h="sm" />
            <Stack gap={"5px"}>
              <Text fw={600}>Support and maintenance.</Text>
              <Text>Untapped Water provides after support & maintenance.</Text>
            </Stack>
          </Card>
        </Stack>
        <Space h={"50px"} />
        <Card
          radius={32}
          style={{
            background: "linear-gradient(94.81deg, #0E7CAF 0%, #156082 100%)",
          }}
          p="xl"
        >
          <Group>
            <Image src="/images/zipporah.svg" />

            <Stack gap={"sm"}>
              <Text size="xl" c={"#fff"} fw={600}>
                Meet Zipporah
              </Text>
              <Text c={"#fff"}>
                Zipporah is married with three children and lives in the Eastern
                part of Kenya. She runs a water and sanitary dispenser site. She
                ensures the machines works efficiently and the pad dispenser is
                always stocked.
              </Text>
            </Stack>
          </Group>
        </Card>
      </Box>
    </section>
  );
}

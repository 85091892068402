import { AppShell, rem } from "@mantine/core";
import Header from "./Header";
import { useDisclosure } from "@mantine/hooks";
import Navbar from "./Navbar";

export default function MainLayout({ children }) {
  const [opened, { toggle }] = useDisclosure();

  return (
    <AppShell
      header={{ height: rem(80) }}
      navbar={{
        width: rem(300),
        breakpoint: "md",
        collapsed: { desktop: true, mobile: !opened },
      }}
    >
      <AppShell.Header>
        <Header toggle={toggle} opened={opened} />
      </AppShell.Header>
      <AppShell.Navbar py="md" px={4}>
        <Navbar toggle={toggle}/>
      </AppShell.Navbar>
      <AppShell.Main>{children}</AppShell.Main>
    </AppShell>
  );
}

import axios from "axios";
import { airtable } from "../lib/api";

export const addProspectiveSponsor = async (props) => {
  const {
    firstName,
    lastName,
    email,
    onSuccess,
    onFailure,
    startLoader,
    stopLoader,
  } = props;

  const prospectiveSponsorsUrl = `${airtable.baseUrl}${airtable.baseId}/prospective_sponsors`;
  const payload = {
    first_name: firstName,
    last_name: lastName,
    email: email,
  };

  try {
    if (startLoader) {
      startLoader();
    }

    await axios.post(prospectiveSponsorsUrl, {
      fields: payload
    }, {
      headers: {
        Authorization: `Bearer ${airtable.apiKey}`,
        "Content-Type": "application/json",
      },
    });

    if (onSuccess) {
      onSuccess();
    }

    if (stopLoader) {
      stopLoader();
    }
  } catch (error) {
    if (onFailure) {
      onFailure();
    }
    if (stopLoader) {
      stopLoader();
    }
  }
};

import {
  Avatar,
  Box,
  Group,
  Stack,
  Text,
  rem,
  useMantineTheme,
} from "@mantine/core";
import React from "react";
import { Colors } from "../../lib/colors";

export default function Empowerment(props) {
  const theme = useMantineTheme();

  return (
    <section id="empowerment">
      <Box p={rem(150)} visibleFrom="md" bg={theme.colors.gray[1]}>
        <img
          src="/images/empowerment.png"
          style={{
            width: "100%",
            height: "60dvh",
            objectFit: "fill",
            borderRadius: "24px",
          }}
          alt="empowerment"
        />
        <Text size="xl" ta={"center"} fw={600} my={"50px"}>
          Empowering local entrepreneurs and CBOs
        </Text>

        <Group grow gap={"50px"} align="flex-start">
          <Stack flex={1}>
            <Avatar radius={"xl"} bg={Colors.brandThree} color={"#fff"}>
              1
            </Avatar>

            <Text c={theme.colors.gray[7]}>
              The project makes approximately $300 in revenue per month. The
              revenue is collected via mobile money (Mpesa) making its
              management and accountability transparent.
            </Text>
          </Stack>
          <Stack flex={1}>
            <Avatar radius={"xl"} bg={Colors.brandThree} color={"#fff"}>
              2
            </Avatar>

            <Text c={theme.colors.gray[7]}>
              Untapped Water retains $150 per month to repay the loan which
              means the entrepreneur/CBO makes $150 during the loan repayment
              period.
            </Text>
          </Stack>
          <Stack flex={1}>
            <Avatar radius={"xl"} bg={Colors.brandThree} color={"#fff"}>
              3
            </Avatar>

            <Text c={theme.colors.gray[7]}>
              After about 15 months the loan is settled, the entrepreneur/CBO
              fully owns the project and keeps all the revenue generated.
            </Text>
          </Stack>
        </Group>
      </Box>

      <Box px={"md"} py={"50px"} hiddenFrom="md" bg={theme.colors.gray[1]}>
        <img
          src="/images/empowerment.png"
          style={{
            width: "100%",
            height: rem(300),
            objectFit: "fill",
          }}
          alt="empowerment"
        />
        <Text size="xl" ta={"center"} fw={600} my={"50px"}>
          Empowering local entrepreneurs and CBOs
        </Text>

        <Stack>
          <Stack flex={1}>
            <Avatar radius={"xl"} bg={Colors.brandThree} color={"#fff"}>
              1
            </Avatar>

            <Text c={theme.colors.gray[7]}>
              The project makes approximately $300 in revenue per month. The
              revenue is collected via mobile money (Mpesa) making its
              management and accountability transparent.
            </Text>
          </Stack>
          <Stack flex={1}>
            <Avatar radius={"xl"} bg={Colors.brandThree} color={"#fff"}>
              2
            </Avatar>

            <Text c={theme.colors.gray[7]}>
              Untapped Water retains $150 per month to repay the loan which
              means the entrepreneur/CBO makes $150 during the loan repayment
              period.
            </Text>
          </Stack>
          <Stack flex={1}>
            <Avatar radius={"xl"} bg={Colors.brandThree} color={"#fff"}>
              3
            </Avatar>

            <Text c={theme.colors.gray[7]}>
              After about 15 months the loan is settled, the entrepreneur/CBO
              fully owns the project and keeps all the revenue generated.
            </Text>
          </Stack>
        </Stack>
      </Box>
    </section>
  );
}

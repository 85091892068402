import { Box, BackgroundImage, rem, Text, Flex } from "@mantine/core";
import React from "react";

export default function Home(props) {
  return (
    <section id="home">
      <BackgroundImage
        src={"/images/background-image.png"}
        style={{
          minHeight: `calc(100vh - ${rem(80)})`,
          height: "100%",
        }}
      >
        <Flex
          h={"100%"}
          direction={"column"}
          justify={"center"}
          style={{
            minHeight: `calc(100vh - ${rem(80)})`,
            height: "100%",
          }}
          visibleFrom="md"
        >
          <Box mx={rem(150)} my={"auto"}>
            <Text fw={700} className="home-page-title" c={"#fff"} mb={"sm"}>
              We are Untapped Water
            </Text>
            <Text fw={500} size="xl" c={"#fff"}>
              We believe that access to clean water is a fundamental right.
              <br />
              Our mission is to empower underserved communities by
              <br />
              providing sustainable water and female hygiene solutions.
            </Text>
          </Box>
        </Flex>

        <Flex
          h={"100%"}
          direction={"column"}
          justify={"center"}
          style={{
            minHeight: `calc(100vh - ${rem(80)})`,
            height: "100%",
          }}
          hiddenFrom="md"
        >
          <Box mx={"md"} my={"md"}>
            <Text fw={700} className="home-page-title" c={"#fff"} mb={"sm"}>
              We are Untapped Water
            </Text>
            <Text fw={500} size="lg" c={"#fff"}>
              We believe that access to clean water is a fundamental right. Our
              mission is to empower underserved communities by providing
              sustainable water and female hygiene solutions.
            </Text>
          </Box>
        </Flex>
      </BackgroundImage>
    </section>
  );
}

import {
  Box,
  useMantineTheme,
  Text,
  rem,
  Space,
  Divider,
  Group,
  Card,
  Stack,
} from "@mantine/core";
import { Colors } from "../../lib/colors";

export default function Equity() {
  const theme = useMantineTheme();

  return (
    <section id="equity">
      <Box p={rem(150)} visibleFrom="md" bg={theme.colors.gray[1]}>
        <Text fw={600} size="xl">
          We believe in shared responsibility
        </Text>
        <Space h="50px" />
        <Text size="45px" fw={600}>
          $5,500,{" "}
          <Text fw={600} component="span" c={theme.colors.gray[5]}>
            {" "}
            a joint effort
          </Text>
        </Text>

        <Divider color={"#000"} my={"30px"} />
        <Group grow gap={"lg"}>
          <Card bg={Colors.brandTwo} radius={0} p="lg">
            <Stack gap={"sm"}>
              <Text size="25px" c={"#fff"} fw={600}>
                $500
              </Text>
              <Text c={"#fff"}>Upfront payment from Zipporah.</Text>
            </Stack>
          </Card>
          <Card bg={Colors.brandThree} radius={0} p="lg">
            <Stack gap={"sm"}>
              <Text size="25px" c={"#fff"} fw={600}>
                $2,000
              </Text>
              <Text c={"#fff"}>Loan from Untapped Water.</Text>
            </Stack>
          </Card>
          <Card bg={Colors.brandOne} radius={0} p="lg">
            <Stack gap={"sm"}>
              <Text size="25px" c={Colors.brandTwo} fw={600}>
                $3,000
              </Text>
              <Text c={Colors.brandTwo}>Donations from donors.</Text>
            </Stack>
          </Card>
        </Group>
      </Box>

      <Box px={"md"} py="50px" hiddenFrom="md" bg={theme.colors.gray[1]}>
        <Text fw={600} size="xl">
          We believe in shared responsibility
        </Text>
        <Space h="50px" />
        <Text size="45px" fw={600}>
          $5,500,{" "}
          <Text fw={600} component="span" c={theme.colors.gray[5]}>
            {" "}
            a joint effort
          </Text>
        </Text>

        <Divider color={"#000"} my={"30px"} />
        <Stack gap={"lg"}>
          <Card bg={Colors.brandTwo} radius={0} p="lg">
            <Stack gap={"sm"}>
              <Text size="25px" c={"#fff"} fw={600}>
                $500
              </Text>
              <Text c={"#fff"}>Upfront payment from Zipporah.</Text>
            </Stack>
          </Card>
          <Card bg={Colors.brandThree} radius={0} p="lg">
            <Stack gap={"sm"}>
              <Text size="25px" c={"#fff"} fw={600}>
                $2,000
              </Text>
              <Text c={"#fff"}>Loan from Untapped Water.</Text>
            </Stack>
          </Card>
          <Card bg={Colors.brandOne} radius={0} p="lg">
            <Stack gap={"sm"}>
              <Text size="25px" c={Colors.brandTwo} fw={600}>
                $3,000
              </Text>
              <Text c={Colors.brandTwo}>Donations from donors.</Text>
            </Stack>
          </Card>
        </Stack>
      </Box>
    </section>
  );
}

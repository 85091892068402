import { Group, Button, Flex, Burger, Image } from "@mantine/core";
import React from "react";
import { Colors } from "../lib/colors";

export default function Header(props) {
  const { toggle, opened } = props;

  return (
    <Flex direction={"column"} justify={"center"} h={"100%"} px={"xl"}>
      <Group justify="space-between" align="center">
        {/* <Image /> */}
        <Image src={"/images/logo.png"} height={70} width={70} />
        <Group gap={"sm"} visibleFrom="md">
          <Button variant="subtle" color="#000" fw={700} size="md" component={"a"} href="#who-we-are">
            Who we are
          </Button>
          <Button variant="subtle" color="#000" fw={700} size="md" component={"a"} href="#contact-us">
            Contact us
          </Button>
          <Button size="md" ml={"lg"} bg={Colors.brandTwo} component="a" href="#get-involved">
            Get involved
          </Button>
        </Group>
        <Burger opened={opened} onClick={toggle} hiddenFrom="md" size="md" />
      </Group>
    </Flex>
  );
}

import React from "react";
import {
  Box,
  Group,
  Paper,
  Space,
  Text,
  rem,
  useMantineTheme,
} from "@mantine/core";
import { Colors } from "../../lib/colors";

export default function CostBreakdown(props) {
  const theme = useMantineTheme();

  return (
    <section id="cost-breakdown">
      <Box p={rem(150)} visibleFrom="md">
        <Box w={{ base: "100%", md: "70%" }}>
          <Text c={theme.colors.gray[7]}>
            Transparency is at the core of Untapped Water, as it fosters trust
            and accountability among our stakeholders. We believe that by openly
            sharing our processes, decisions, and data, we can create a more
            informed community that is engaged in the vital mission of
            sustainable water and female hygiene management.
          </Text>
        </Box>
        <Space h="50px" />
        <Group flex={1} w={"100%"} align="flex-start">
          <Box flex={0.6}>
            <Paper h={rem(35)} radius={10} bg={Colors.brandTwo} />
            <Text c={theme.colors.gray[9]} size="xs" mt={5}>
              Machine Installation
            </Text>
          </Box>
          <Box flex={0.4}>
            <Paper h={rem(35)} radius={10} bg={theme.colors.gray[3]} />
            <Text c={theme.colors.gray[9]} size="xs" mt={5}>
              Team’s Compensation
            </Text>
          </Box>
          <Box flex={0.1}>
            <Paper h={rem(35)} radius={10} bg={Colors.brandOne} />
            <Text c={theme.colors.gray[9]} size="xs" mt={5}>
              Logistics & After-Support
            </Text>
          </Box>
        </Group>
        <Space h="50px" />
        <Text size="45px" fw={600}>
          = $5,500 total
        </Text>
      </Box>

      <Box px={"md"} py={"50px"} hiddenFrom="md">
        <Box w={{ base: "100%", md: "70%" }}>
          <Text c={theme.colors.gray[7]}>
            Transparency is at the core of Untapped Water, as it fosters trust
            and accountability among our stakeholders. We believe that by openly
            sharing our processes, decisions, and data, we can create a more
            informed community that is engaged in the vital mission of
            sustainable water and female hygiene management.
          </Text>
        </Box>
        <Space h="50px" />
        <Group flex={1} w={"100%"} align="flex-start">
          <Box flex={0.6}>
            <Paper h={rem(35)} radius={10} bg={Colors.brandTwo} />
            <Text c={theme.colors.gray[9]} size="xs" mt={5}>
              Machine Installation
            </Text>
          </Box>
          <Box flex={0.4}>
            <Paper h={rem(35)} radius={10} bg={theme.colors.gray[3]} />
            <Text c={theme.colors.gray[9]} size="xs" mt={5}>
              Team’s Compensation
            </Text>
          </Box>
          <Box flex={0.1}>
            <Paper h={rem(35)} radius={10} bg={Colors.brandOne} />
            <Text c={theme.colors.gray[9]} size="xs" mt={5}>
              Logistics & After-Support
            </Text>
          </Box>
        </Group>
        <Space h="50px" />
        <Text size="45px" fw={600}>
          = $5,500 total
        </Text>
      </Box>
    </section>
  );
}

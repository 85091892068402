import React from "react";
import {
  Avatar,
  Box,
  Card,
  Group,
  SimpleGrid,
  Space,
  Text,
  rem,
  useMantineTheme,
} from "@mantine/core";
import { Colors } from "../../lib/colors";

const testimonials = [
  {
    message:
      "“Receiving financial support from Untapped Water made my dream of providing sustainable water and female hygiene solutions to my community come true”",
    name: "Zipporah Kemoi",
    job: "MD Hydrostill",
    image: "",
    avatarName: "Zipporah Kemoi",
  },
  {
    message:
      "“Before Untapped Water, poor revenue collection and water wastage was the norm. Their solution streamlined this for us and we can now provide water and farm efficiently”",
    name: "Alex Mwongela",
    job: "CEO Yaaqovs",
    image: "",
    avatarName: "Alex Mwongela",
  },
  {
    message:
      "“The whole of the Western Region has no water. Continue supporting them like you supported my brother-in-law because many people are beneficiaries of the support.”",
    name: "Mrs Okila Mbalanya",
    job: "Sister-in-Law - Benson (CEO Esitao)",
    image: "",
    avatarName: "Okila Mbalanya",
  },
];

export default function Testimonials(props) {
  const theme = useMantineTheme();

  return (
    <section id="testimonial">
      <Box p={rem(150)} visibleFrom="md" bg={Colors.brandThree}>
        <SimpleGrid cols={3}>
          {testimonials?.map((item, index) => (
            <Card radius={16} p={"xl"} mih={rem(200)} key={index}>
              <Text size="sm" lineClamp={6}>
                {item.message}
              </Text>
              <Space h="sm" />
              <Group>
                <Avatar
                  size={"40px"}
                  radius={8}
                  name={item.avatarName}
                  color="initials"
                ></Avatar>

                <Box>
                  <Text fw={600} size="sm">
                    {item.name}
                  </Text>
                  <Text c={theme.colors.gray[7]} size="xs">
                    {item.job}
                  </Text>
                </Box>
              </Group>
            </Card>
          ))}
        </SimpleGrid>
      </Box>

      <Box hiddenFrom="md" px={"md"} py={"50px"} bg={Colors.brandThree}>
        <SimpleGrid cols={1}>
          {testimonials.map((item, index) => (
            <Card radius={16} p={"xl"} mih={rem(200)} key={index}>
              <Text size="sm" lineClamp={6}>
                {item.message}
              </Text>
              <Space h="sm" />
              <Group>
                <Avatar
                  size={"40px"}
                  radius={8}
                  name={item.avatarName}
                  color="initials"
                ></Avatar>

                <Box>
                  <Text fw={600} size="sm">
                    {item.name}
                  </Text>
                  <Text c={theme.colors.gray[7]} size="xs">
                    {item.job}
                  </Text>
                </Box>
              </Group>
            </Card>
          ))}
        </SimpleGrid>
      </Box>
    </section>
  );
}

import { NavLink } from "@mantine/core";
import { Colors } from "../lib/colors";

export default function Navbar(props) {
  const { toggle } = props;

  const links = [
    {
      label: "Who we are",
      href: "#who-we-are",
      bg: "",
    },
    {
      label: "Contact us",
      href: "#contact-us",
      bg: "",
    },
    {
      label: "Get involved",
      href: "#get-involved",
      bg: Colors.brandTwo,
      c: "#fff",
    },
  ];

  return (
    <>
      {links?.map((link, index) => (
        <NavLink
          key={index}
          {...link}
          fw={600}
          style={{
            borderRadius: "4px",
          }}
          onClick={toggle}
        />
      ))}
    </>
  );
}
